import { Offer } from 'core/offers/entities';
import { Description, Bold } from './styled';

export type SectionPaymentContentOtherLocationProps = {
  offer: Offer;
  trialPeriod: string;
};

const SectionPaymentContentOtherLocation = ({ offer }: SectionPaymentContentOtherLocationProps) => {
  return (
    <Description>
      You will be charged <Bold>{offer.pricesWithCurrencySymbol.priceWithVAT}</Bold> for your
      auto-renewing subscription. We’ll <Bold>email you a reminder</Bold> before the end of each
      subscription renewal period. Cancel anytime.
    </Description>
  );
};

export default SectionPaymentContentOtherLocation;
